import React from 'react';
import classnames from 'classnames';
import { Caption } from './Caption';

import s from './Figure.module.scss';

type Props = {
  className?: string;
  caption?: React.ReactNode;
  children: React.ReactNode;
};

export const Figure = ({ caption, className, children }: Props) => {
  const hasCaption =
    caption &&
    ((typeof caption === 'string' && caption.length > 0) ||
      (typeof caption !== 'string' && React.Children.count(caption) > 0));

  return (
    <figure className={classnames(s.figure, className)}>
      {children}
      {hasCaption && (
        <figcaption className={s.figure__caption}>
          <Caption caption={caption} />
        </figcaption>
      )}
    </figure>
  );
};
