import React from 'react';
import classnames from 'classnames';

import { IEmbed } from 'utils/types';
import { VideoOverlay } from 'components/video/VideoOverlay';

import s from './PlayButton.module.scss';

type Props = {
  className?: string;
  embed?: IEmbed;
  ariaLabel?: string;
};

export const PlayButton = ({ embed, className, ariaLabel }: Props) => {
  const [isOpen, setOpen] = React.useState(false);

  return (
    <>
      <div className={classnames(s.playButton, className)}>
        <button
          className={s.playButton__button}
          onClick={() => setOpen(true)}
          aria-label={ariaLabel ? ariaLabel : 'Play video'}
        />
      </div>

      {embed && isOpen && <VideoOverlay embed={embed} onClose={() => setOpen(false)} />}
    </>
  );
};
