import React from 'react';
import classnames from 'classnames';

import s from './CoverVideo.module.scss';

type Props = {
  video: string;
  image?: string;
  className?: string;
};

export const CoverVideo = ({ video, image, className }: Props) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isVideoReady, setVideoStatus] = React.useState(false);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current?.load();
      // videoRef.current.play();
    }
  }, []);

  return (
    <div className={classnames(s.coverVideo, className)} aria-hidden="true">
      <video
        className={classnames(s.coverVideo__video, { [s.ready]: isVideoReady })}
        ref={videoRef}
        src={video}
        autoPlay
        playsInline
        loop
        muted
        poster={image}
        onPlaying={() => {
          // console.log('onPlaying');
          setVideoStatus(true);
        }}
        onCanPlay={() => {
          // console.log('onCanPlay');
          setVideoStatus(true);
        }}
        role="presentation"
      />
    </div>
  );
};
