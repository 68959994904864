import React from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import s from './ReactPlayerStrict.module.scss';

export const ReactPlayerStrict = ({ url, ...props }: ReactPlayerProps) => {
  const [consented, setConsented] = React.useState(false);

  React.useEffect(() => {
    const onAccept = () => {
      const Cookiebot = window['Cookiebot'];

      if (!Cookiebot?.consent.marketing) {
        props.onStart?.();
      }

      setConsented(Cookiebot?.consent.marketing ?? false);
    };

    onAccept();

    window.addEventListener('CookiebotOnAccept', onAccept);

    return () => {
      window.removeEventListener('CookiebotOnAccept', onAccept);
    };
  }, []);

  if (consented === false) {
    return (
      <div className={s.message}>
        <div className={s.message__inner}>
          <p>
            Please{' '}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window['Cookiebot']?.renew();
              }}
            >
              accept marketing-cookies
            </a>{' '}
            to watch this video.
          </p>

          <p>You may need to disable adblocking extensions.</p>
        </div>
      </div>
    );
  }

  return <ReactPlayer url={url} {...props} />;
};
