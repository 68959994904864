import React from 'react';
import ReactDOM from 'react-dom';

import { ReactPlayerStrict } from 'components/video/ReactPlayerStrict';

import { IEmbed } from 'utils/types';

import s from './VideoOverlay.module.scss';

type Props = {
  embed: IEmbed;
  onClose?: () => void;
};

export const VideoOverlay = ({ embed, onClose = () => {} }: Props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  const closeRef = React.useRef<HTMLButtonElement | null>(null);

  // React.useEffect(() => {
  //   document.body.classList.add('no-scroll');

  //   return () => {
  //     document.body.classList.remove('no-scroll');
  //   };
  // }, []);

  React.useEffect(() => {
    window.document.addEventListener('keydown', onKey);
    document.getElementById('pageLayout')!.setAttribute('aria-hidden', 'true');
    const previousFocus = document!.activeElement;
    closeRef!.current!.focus();

    return () => {
      window.document.removeEventListener('keydown', onKey);
      document.getElementById('pageLayout')!.removeAttribute('aria-hidden');
      if (previousFocus) {
        // @ts-ignore
        previousFocus.focus();
      } else {
        // @ts-ignore
        document!.activeElement!.blur();
      }
    };
  }, []);

  const onKey = (e: any) => {
    if (e.keyCode === 27) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={s.videoOverlay} role="alertdialog" aria-modal="true" aria-label="Video modal">
      <button
        ref={closeRef}
        className={s.videoOverlay__close}
        onClick={onClose}
        aria-label="Close video modal"
      />
      <div className={s.videoOverlay__foo}>
        <div className={s.videoOverlay__aspect}>
          <div className={s.videoOverlay__player}>
            <ReactPlayerStrict
              url={embed.embedUrl}
              width="100%"
              height="100%"
              playing
              controls={true}
            />
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
