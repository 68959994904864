import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';
import VisibilitySensor from 'react-visibility-sensor';

import { IEmbed, IImage, ILink } from 'utils/types';
import { IconButton } from 'components/button/IconButton';
import { PlayButton } from 'components/button/PlayButton';
import { TextButton } from 'components/button/TextButton';
import { Tagline } from 'components/tagline/Tagline';
import { CoverVideo } from 'components/video/CoverVideo';
import { Fade } from 'components/animations/Fade';
import { Figure } from 'components/media/Figure';

import s from './Overlap.module.scss';

type Props = {
  index?: number;
  tagline?: string;
  title?: string;
  text?: React.ReactNode;
  link?: ILink;
  label?: string;
  secondLink?: ILink;
  secondLabel?: string;
  image?: IImage;
  direction?: string;
  video?: ILink;
  embed?: IEmbed;
  caption?: React.ReactNode;
};

export const OverlapItem = ({
  tagline,
  title,
  text,
  index,
  image,
  link,
  label,
  secondLink,
  secondLabel,
  direction,
  embed,
  video,
  caption,
}: Props) => {
  const reverse = direction === 'Left';
  const baseDelay = (title ? 0.15 : 0) + (tagline ? 0.15 : 0);
  const twoLinks = link && secondLink;

  return (
    <div className={classnames(s.item, { [s.reverse]: reverse })}>
      <VisibilitySensor partialVisibility={true}>
        {({ isVisible }) => (
          <div className={s.item__layout}>
            <div className={s.item__contentCol}>
              {tagline && (
                <Fade isVisible={isVisible}>
                  <Tagline index={index} text={tagline} />
                </Fade>
              )}
              {title && (
                <Fade isVisible={isVisible} delay={tagline ? 0.15 : 0}>
                  <h3 className={s.item__title}>{title}</h3>
                </Fade>
              )}
              {text && (
                <Fade isVisible={isVisible} delay={baseDelay}>
                  <div className={s.item__row}>
                    <div className={s.item__text}>
                      <div className={s.item__copy}>{text}</div>
                    </div>
                  </div>
                </Fade>
              )}
              <div className={classnames(s.item__buttons, { [s.inline]: twoLinks })}>
                {link && (
                  <Fade isVisible={isVisible} delay={baseDelay + 0.15}>
                    <div className={s.item__link}>
                      {twoLinks ? (
                        <TextButton color="white" {...link} aria-label={title}>
                          {label || 'Read more'}
                        </TextButton>
                      ) : (
                        <IconButton color="white" {...link} aria-label={title}>
                          {label || 'Read more'}
                        </IconButton>
                      )}
                    </div>
                  </Fade>
                )}
                {secondLink && (
                  <Fade isVisible={isVisible} delay={baseDelay + 0.3}>
                    <div className={s.item__link}>
                      {twoLinks ? (
                        <TextButton color="white" {...secondLink} aria-label={title}>
                          {secondLabel || 'Read more'}
                        </TextButton>
                      ) : (
                        <IconButton color="white" {...secondLink} aria-label={title}>
                          {secondLabel || 'Read more'}
                        </IconButton>
                      )}
                    </div>
                  </Fade>
                )}
              </div>
            </div>
            <div className={s.item__imageCol}>
              {image && (
                <Fade isVisible={isVisible}>
                  <Figure className={s.item__figure} caption={caption}>
                    <div className={s.item__image}>
                      <div className={s.item__aspect} />
                      {embed && (
                        <PlayButton
                          className={s.item__play}
                          embed={embed}
                          ariaLabel={`Play video ${title}`}
                        />
                      )}
                      {video && <CoverVideo className={s.item__video} video={video.href} />}
                      {image && <Image src={image.url} layout="fill" objectFit="cover" />}
                    </div>
                  </Figure>
                </Fade>
              )}
            </div>
          </div>
        )}
      </VisibilitySensor>
    </div>
  );
};
