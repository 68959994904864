import React from 'react';
import { BackgroundSection } from 'components/background/BackgroundSection';

import s from './Content.module.scss';

type Props = {
  isSlice?: boolean;
  children: React.ReactNode;
};

export const Content = ({ isSlice, children }: Props) => {
  const container = (
    <BackgroundSection>
      <div className={s.content__segment}>
        <div className={s.content__container}>
          <div className={s.content__row}>
            <div className={s.content__col}>{children}</div>
          </div>
        </div>
      </div>
    </BackgroundSection>
  );

  return <div className={s.content}>{isSlice ? container : children}</div>;
};
